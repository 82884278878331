import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Container from "layout/Container"
import sitemap from "../../../static/data/sitemap.json"

const Footer = () => {
  return (
    <footer className="footer px-1">
      <Container isCentered mobile={12} tablet={9} desktop={10}>
        <div className="columns">
          {sitemap.map((section, index) => (
            <div
              className={classNames(section.classnames, "column is-4")}
              key={index}
            >
              <h5 className="mt-0 has-text-primary">{section.section}</h5>
              {section.links.map(link => (
                <Link
                  to={`/${link.url}`}
                  className="help has-text-dark"
                  key={link.url}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          ))}
        </div>
        <p className="help pt-1 pl-0 ml-0">PC-PH-103072 | MARCH 2022</p>
      </Container>
    </footer>
  )
}

export default Footer
